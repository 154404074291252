.no_bed {
  position: absolute;
  font-size: 22px;
  top: 13px;
  left: 12px;
}
.bed_delete {
  position: absolute;
  /* font-size: 22px; */
  /* top: 52px; */
  left: 40px;
}
